import { Link } from "react-router-dom";

import{
    Button,
} from '@mui/material'

const CustomButton = (props) => {
    return (
        <Button type="submit" variant="contained" color="primary" onClick={props.onClick} component={Link} to={ props.to } >
            { props.children }
        </Button>
    )
}

export default CustomButton