import { capitalize } from 'utils'

import{
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@mui/material'

const handleChange = (event) => {
    return event.target.value
}

const CustomSelect = (props) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                fullWidth
            >
                <InputLabel id="simple-select-standard-label">{props.label}</InputLabel>
                <Select
                    labelId="simple-select-standard-label"
                    id="simple-select-standard"
                    value={props.value}
                    onChange={(event) => props.onChange(event.target.value)}
                    label={props.label}
                    inputProps={{
                        readOnly: props.readonly,
                    }}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { props.data ?
                    props.data.map((item, i) => {
                        return (
                            <MenuItem key={i} value={item[props.field]}>{capitalize(item.name)}</MenuItem>
                        )
                    })
                    : null }
                </Select>
            </FormControl>
        </Grid>
    )
}

export default CustomSelect

