import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router';

import withSession from "hocs/withSession"

import { server_url } from 'config'
import { getItem } from 'utils'

import{
    Toolbar,
    Box,
} from '@mui/material'

import Form from 'components/Form'
import TextField from 'components/TextField'
import Switch from 'components/Switch'
import Button from 'components/Button'

import AppLayout from 'layout/AppLayout'

const title = 'Edit category'

const EditCategory = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState(true)
    const [name, setName] = useState('')
    const [active, setActive] = useState(false)
    const [image, setImage] = useState('')

    const fetchCategory = (id) => {
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            }
        }

        let url = `${server_url}/categories/${id}` 

        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            setName(data.name)
            setActive(data.active)
            setImage(data.image)

            setLoading(false)
        })
    }

    const saveCategory = () => {
        let data = {
            name : name,
            active : active,
            image : image,
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        let url = `${server_url}/categories/save`
        if(id){
            url = `${server_url}/categories/${id}/save`
        }
        
        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            navigate(`/categories/`)
            navigate(0)
        })
    }

    useEffect(() => {
        // console.log(id)
        if(id){
            fetchCategory(id)
        }else{
            setLoading(false)
        }
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                <Box sx={{ flexGrow : 1 }}/>
                <Button onClick={saveCategory}>Save</Button>
            </Toolbar>

            <Form>
                <TextField label="Name" value={name} onChange={setName} />
                <TextField label="Image" value={image} onChange={setImage} />
                <Switch label="Active" value={active} onChange={setActive} />
            </Form>
        </AppLayout>
    )
}

export default withSession(EditCategory)