import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'

const title = 'Main'

const Index = () => {
    return (
        <AppLayout title={title}>
            Index
        </AppLayout>
    )
}

export default withSession(Index)