import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router';

import{
    Grid,
    Toolbar,
    Box,
    Paper,
} from '@mui/material'

import { server_url } from 'config'
import { getItem, capitalize } from 'utils'

import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'
import BasicTable from 'components/BasicTable'
import TextField from 'components/TextField'
import Form from 'components/Form'
import DatePicker from 'components/DatePicker'
import Select from 'components/Select'
import Button from 'components/Button'

const title = 'New tournament'

const players_columns = [
    { title: 'Nickname', field: 'nickname' },
    { title: 'Score',    field: 'score'    },
    { title: 'Rounds',   field: 'rounds'   },
    { title: 'Kills',    field: 'kills'    },
    { title: 'Assists',  field: 'assists'  },
    { title: 'Deaths',   field: 'deaths'   },
    { title: 'Damage',   field: 'damage'   },
]

const maps = [
    {
        name : "GenArea2",
        id : "Map.GenArea2"
    },
    {
        name : "Quantum",
        id : "Map.generator"
    }
]

const gameplay_types = [
    {
        name : "Elimination",
        id : 3
    }
]

const round = {
    number : 1,
    wait_time : 5,
    duration : 20,
    map : "Arena",
    gameplay_type: "3",
}

const prize = {
    position : 1,
    prize : ''
}

const conditions_type = [
    {
        name : "Token",
        id : "token"
    },
    {
        name : "Level",
        id : "level"
    },
]

const NewTorunament = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [readonly, setLReadOnly] = useState(false)

    const [loading, setLoading] = useState(true)
    const [regions, setRegions] = useState([])

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState('')
    const [background, setBackground] = useState('')
    const [logo, setLogo] = useState('')
    const [status, setStatus] = useState('new')
    const [region, setRegion] = useState('us')
    const [min_players, setMinPlayers] = useState(20)
    const [max_players, setMaxPlayers] = useState(200)
    const [start_date, setStartDate] = useState('')
    const [end_date, setEndDate] = useState('')
    const [num_players, setNumPlayers] = useState(0)
    const [players, setPlayers] = useState([])

    const [conditions, setConditions] = useState([])
    const [prizes, setPrizes] = useState([prize])
    const [rounds, setRounds] = useState([round])

    const fetchRegions = () => {
        fetch(`${server_url}/regions/list`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setRegions(data)
        })
    }

    const fetchPlayers = (id) => {
        fetch(`${server_url}/tournaments/${id}/players`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setPlayers(data)
        })
    }

    const fetchTournament = (id) => {
        fetch(`${server_url}/tournaments/${id}`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            if(data.status != 'created' || data.status != 'new' ){
                fetchPlayers(id)
            }

            if(data.status == 'created' || data.status == 'new'){
                setLReadOnly(false)
            }

            setStatus(data.status)
            setName(data.name)
            setDescription(data.description)
            setImage(data.image)
            setBackground(data.background)
            setLogo(data.logo)
            setStatus(data.status)
            setRegion(data.region)
            setStartDate(data.start_date)
            setStartDate(data.start_date)
            setEndDate(data.end_date)
            setMinPlayers(data.min_players)
            setMaxPlayers(data.max_players)
            setPrizes(data.prizes)
            setRounds(data.rounds)
            setNumPlayers(data.num_players)
            setConditions(data.conditions)

            setLoading(false)
        })
    }

    const saveTournament = () => {
        console.log("Save tournaments")

        let data = {
            name : name,
            description : description,
            rounds : rounds,
            num_rounds : rounds.length,
            image : image,
            logo : logo,
            background : background,
            start_date : start_date,
            end_date : end_date,
            min_players : min_players,
            max_players : max_players,
            prizes : prizes,
            conditions: conditions,
        }

        console.log(data);

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        let url = `${server_url}/tournaments/save`
        if(id){
            url = `${server_url}/tournaments/${id}/save`
        }
        
        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            navigate(`/tournaments/edit/${data._id}`)
            navigate(0)
        })
    }

    const activateTournament = () => {
        let data = {
            status : 'active'
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        let url = `${server_url}/tournaments/${id}/save`
        
        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            navigate(`/tournaments/edit/${data._id}`)
            navigate(0)
        })
    }

    const addCondition = () => {
        let c_cond = conditions.slice()
        
        c_cond.push({
            condition : undefined,
            value : undefined,
        })

        setConditions(c_cond)
    }

    const addPrize = () => {
        let postion = prizes.length + 1

        let c_prizes = prizes.slice()
        
        c_prizes.push({
            position : postion,
            prize : ''
        })

        setPrizes(c_prizes)
    }

    const setPrize = (i, value) => {
        let c_prizes = prizes.slice()

        c_prizes[i].prize = value

        setPrizes(c_prizes)
    }

    const addRound = () => {
        let number = rounds.length + 1
        let c_rounds = rounds.slice()

        c_rounds.push({
            number : number,
            wait_time : 5,
            duration : 20,
            map : "GenArea2",
            gameplay_mode: "elimination",
        })

        setRounds(c_rounds)
    }

    const setConditionData = (i, key, value) => {
        console.log(i, key, value)

        let c_cond = conditions.slice()

        c_cond[i][key] = value

        setRounds(c_cond)
    }

    const setRoundData = (i, key, value) => {
        console.log(i, key, value)

        let c_rounds = rounds.slice()

        c_rounds[i][key] = value

        setRounds(c_rounds)
    }

    useEffect(() => {
        // console.log(id)
        if(id){
            fetchTournament(id)
        }else{
            setLoading(false)
        }

        fetchRegions()
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                Status: { capitalize(status) }
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'created' ? <Button onClick={activateTournament}>Activate</Button> : null}
                <Box sx={{ width : 10 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={saveTournament}>Save</Button> : null}
            </Toolbar>

            <Form>
                <TextField label="Name" value={name} onChange={setName} readonly={readonly} />
                <TextField label="Description" value={description} onChange={setDescription} readonly={readonly} />
                <TextField label="Image URL" value={image} onChange={setImage} readonly={readonly} />
                <TextField label="Background URL" value={background} onChange={setBackground} readonly={readonly} />
                <TextField label="Logo URL 200x200"  value={logo} onChange={setLogo} readonly={readonly} />
             {/*  <DatePicker label="Start date" value={start_date} onChange={setStartDate} readonly={readonly} />
                <DatePicker label="End date"  value={end_date} onChange={setEndDate} readonly={readonly} />  */}
                <Select label="Region" data={regions} field="code" value={region} onChange={setRegion} readonly={readonly} />
                <TextField label="Min players" type="number" value={min_players} onChange={setMinPlayers} readonly={readonly} />
                <TextField label="Max players" type="number" value={max_players} onChange={setMaxPlayers} readonly={readonly} />
            </Form>

            <Toolbar>
                Conditions
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={addCondition} >Add condition</Button> : null}
            </Toolbar>

            {
                conditions.map((condition, i) => {
                    return (
                        <Form>
                            <Select label="Type" data={conditions_type} field="id" value={condition.condition} onChange={(value) => setConditionData(i, 'condition', value)} readonly={readonly} />
                            <TextField label="Description" value={condition.description} onChange={(value) => setConditionData(i, 'description', value)} readonly={readonly} />
                            <TextField label="Value" value={condition.value} onChange={(value) => setConditionData(i, 'value', value)} readonly={readonly} />
                            <TextField label="Monto" value={condition.amount} onChange={(value) => setConditionData(i, 'amount', value)} readonly={readonly} />
                        </Form>
                    )
                })
            }

            <Toolbar>
                Prizes
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={addPrize} >Add prize</Button> : null}
            </Toolbar>

            {
                prizes.map((prize, i) => {
                    return (
                        <Form>
                            <TextField label="Position" type="number" value={prize.position} readonly={readonly} />
                            <TextField label="Prize" value={prize.prize} onChange={(value) => setPrize(i, value)} readonly={readonly} />
                        </Form>
                    )
                })
            }

            <Toolbar>
                Num of rounds: { rounds.length }
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={addRound} >Add round</Button> : null}
            </Toolbar>

            {
                rounds.map((round, i) => {
                    return (
                        <Form title={`Round ${ round.number }`}>
                            <DatePicker label="Date" value={round.date} onChange={(value) => setRoundData(i, 'date', value)} readonly={readonly} />
                            <TextField label="Waiting time (min)" type="number" value={round.wait_time} onChange={(value) => setRoundData(i, 'wait_time', value)} readonly={readonly} />
                            <TextField label="Duration (min)" type="number" value={round.duration} onChange={(value) => setRoundData(i, 'duration', value)} readonly={readonly} />
                            <Select label="Map" data={maps} field="id" value={round.map} onChange={(value) => setRoundData(i, 'map', value)} readonly={readonly} />
                            <Select label="Gameplay Type" data={gameplay_types} field="id" value={round.gameplay_type} onChange={(value) => setRoundData(i, 'gameplay_type', value)} readonly={readonly} />
                            <TextField label="Players" type="number" value={round.players} onChange={(value) => setRoundData(i, 'players', value)} readonly={readonly} />
                        </Form>
                    )
                })
            }

            { status != 'new' && status != 'created' ? <div>
                <Toolbar>
                    Players: { num_players }
                    <Box sx={{ flexGrow : 1 }}/>
                </Toolbar>
                { players.length ? <BasicTable columns={players_columns} data={players} /> : <div>No players suscribed yet!</div>}

            </div> : null}
        </AppLayout>
    )
}

export default withSession(NewTorunament)