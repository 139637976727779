import { HashRouter as Router, Route, Routes } from 'react-router-dom'

import Index from "pages/index";
import Login from "pages/login";

import ListTournaments from "pages/tournaments/list";
import EditTournaments from "pages/tournaments/edit";

import ListBloomies from "pages/bloomies/list";
import EditBloomies from "pages/bloomies/edit";

import ListCategories from "pages/categories/list";
import EditCategories from "pages/categories/edit";

import ListProducts from "pages/products/list";
import EditProducts from "pages/products/edit";

import ListPrizesc from "pages/prizesc/list";
import EditPrizesc from "pages/prizesc/edit";

import ListPrizesSpin from "pages/prizesSpin/list";
import EditPrizesSpin from "pages/prizesSpin/edit";


import './App.css';

function App() {
  return (
      <div className="App">
            <Router >
                <Routes>
                    <Route path='/' element={<Index/>} />
                    <Route path='/login' element={<Login/>} />
                    <Route path='/tournaments' element={<ListTournaments/>} />
                    <Route path='/tournaments/new' element={<EditTournaments/>} />
                    <Route path='/tournaments/edit/:id' element={<EditTournaments/>} />

                    <Route path='/bloomies' element={<ListBloomies/>} />
                    <Route path='/bloomies/new' element={<EditBloomies/>} />
                    <Route path='/bloomies/edit/:id' element={<EditBloomies/>} />


                    <Route path='/categories' element={<ListCategories/>} />
                    <Route path='/categories/new' element={<EditCategories/>} />
                    <Route path='/categories/edit/:id' element={<EditCategories/>} />

                    <Route path='/products' element={<ListProducts/>} />
                    <Route path='/products/new' element={<EditProducts/>} />
                    <Route path='/products/edit/:id' element={<EditProducts/>} />

                    <Route path='/prizesc' element={<ListPrizesc/>} />
                    <Route path='/prizesc/new' element={<EditPrizesc/>} />
                    <Route path='/prizesc/edit/:id' element={<EditPrizesc/>} />

                    <Route path='/prizesSpin' element={<ListPrizesSpin/>} />
                    <Route path='/prizesSpin/new' element={<EditPrizesSpin/>} />
                    <Route path='/prizesSpin/edit/:id' element={<EditPrizesSpin/>} />

                </Routes>
            </Router>
      </div>
  );
}

export default App;