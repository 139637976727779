import { useState } from "react"

import { useNavigate } from "react-router-dom"
import { useSnackbar } from 'notistack'

import withSession from "hocs/withSession"

import { server_url } from 'config'
import { getItem, setItem } from 'utils';

import {
    Box,
    Paper,
} from '@mui/material'

import AppLayout from 'layout/AppLayout'
import Form from 'components/Form'
import TextField from 'components/TextField'
import Button from 'components/Button'

const title = 'Login'

const Login = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const login = (e) => {
        e.preventDefault()

        let data = {
            username : username,
            password : password,
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        }
        
        fetch(`${server_url}/users/login`, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            if(data.token){
                setItem('token', data.token)

                navigate('/')
            }else{
                enqueueSnackbar(data.error)
            }
        })
    }

    return (
        <AppLayout title={title} header={false} drawer={false} >

            <Box sx={{ position: 'absolute', top: '50%', left: '50%', marginRight: '-50%', transform: 'translate(-50%, -50%)', minWidth : 300 }}>
                <Paper>
                    <Box sx={{ flexGrow: 1, padding : 2 }}>
                        <Box sx={{ marginBottom : 2 }}>
                            <form noValidate onSubmit={login}>
                                <TextField value={username} onChange={setUsername} label='Username' />
                                <TextField value={password} onChange={setPassword} label='Password' type='password' />
                            </form>
                        </Box>
                        <Button onClick={login}>Login</Button>
                    </Box>
                </Paper>
            </Box>
            
        </AppLayout>
    )
}

export default withSession(Login)