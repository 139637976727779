import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import{
    Toolbar,
    Box,
} from '@mui/material'

import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'
import BasicTable from 'components/BasicTable'
import Button from 'components/Button'

import { server_url } from 'config'
import { getItem } from 'utils'

const title = 'Bloomies packages'

let columns = [
    { title: 'Name',    field: 'name', type : 'capitalize' },
    { title: 'Solana',   field: 'solana', type : 'currency' },
    { title: 'Bloomies',   field: 'bloomies', type : 'currency' },
    { title: 'Active',  field: 'active', type : 'active' },
]

const ListBloomies = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const fetchData = () => {
        let options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            }
        }

        fetch(`${server_url}/bloomies/list`, options)
        .then(response => {
            setLoading(false)

            return response.json()
        })
        .then(data => {
            // console.log(data)

            setData(data)
        })
    }

    const editCategory = (row) => {
        console.log(row)
        navigate(`/bloomies/edit/${row._id}`)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                <Box sx={{ flexGrow : 1 }}/>
                <Button to='/bloomies/new' >
                    New package
                </Button>
            </Toolbar>
            { data.length ? <BasicTable columns={columns} data={data} onRowClick={editCategory} /> : <div>No bloomies packages to show</div>}
        </AppLayout>
    )
}

export default withSession(ListBloomies)