import moment from "moment";

import { capitalize, currencyFormat } from 'utils'

import {
    Paper,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material'

const BasicTable = (props) => {
    console.log(props.column,"column")
    const renderCell = (row, column) => {
        switch(column.type) {
            // case 'time' : {
            //     return moment.utc(row[column.field]).local().format('HH:mm')
            //     break
            // }
            // case 'date' : {
            //     return moment.utc(row[column.field]).local().format('YYYY-MM-DD')
            //     break
            // }
            case 'datetime' : {
                if(row[column.field] != ""){
                    return moment.utc(row[column.field]).local().format('YYYY-MM-DD HH:mm') 
                }else{
                    return ""
                }
                
                break
            }
            case 'currency' : {
                return currencyFormat(row[column.field])
                break
            }

            case 'datetimearray' : {
                var str = '';
                row[column.field].forEach((item, i) => {
                    str += moment.utc(item.date).local().format('YYYY-MM-DD HH:mm') + ' '
                })
                return str;
                break
            }
            // case 'numeric' : {
            //     return parseFloat(row[column.field]).toFixed(2)
            //     break
            // }
            case 'count' : {
                return row[column.field].length
                break
            }
            // case 'title' : {
            //     return titleCase(row[column.field])
            //     break
            // }
            case 'capitalize' : {
                return capitalize(row[column.field])
                break
            }
            // case 'upper' : {
            //     return row[column.field].toUpperCase()
            //     break
            // }
            case 'active' : {
                return (row[column.field] ? 'Active' : 'Inactive')
                break
            }
            default : {
                return row[column.field]
            }
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {props.columns.map((column, i) => (
                            (column.hidden ? null : <TableCell key={i} variant='head'>{column.title}</TableCell>)
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data ? props.data.map((row, r) => (
                        row ? 
                        <TableRow key={r} hover={true} onClick={props.onRowClick ? () => { props.onRowClick(row) } : null} >
                            {props.columns.map((column, c) => (
                                (column.hidden ? null :
                                <TableCell key={c} component="td" scope="row">
                                    { renderCell(row, column) }
                                </TableCell>)
                            ))}
                        </TableRow>
                        : null
                    )) :  null}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default BasicTable;