//SERVER
exports.app_name = 'Bloomverse - Admin'
exports.server_url = 'https://api.bloomverse.io/'
//exports.server_url = 'http://127.0.0.1:8103'
/*if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    exports.server_url = 'http://127.0.0.1:8103'
} else {
    exports.server_url = 'https://apistage.bloomverse.io/'
}*/

exports.table_options = {
    filterType : 'checkbox',
    selectableRows : 'simple',
    pagination : true,
    responsive : 'scroll',
    rowsPerPageOptions : [10, 50, 100],
    rowHover : true,
    fixedHeader : false,
    
    textLabels: {
        body: {
            noMatch: "No hay elementos",
            toolTip: "Ordenar",
        },
        pagination: {
            next: "Pagina siguiente",
            previous: "Pagina anterior",
            rowsPerPage: "Elementos por pagina:",
            displayRows: "de",
        },
        toolbar: {
            search: "Buscar",
            downloadCsv: "Descargar CSV",
            print: "Imprimir",
            viewColumns: "Ver columnas",
            filterTable: "Filtrar",
        },
        filter: {
            all: "Todos",
            title: "FILTROS",
            reset: "BORRAR",
        },
        viewColumns: {
            title: "Mostrar columnas",
            titleAria: "Mostrar/Ocultar columnas",
        },
        selectedRows: {
            text: "elemento(s) seleccionados",
            delete: "Eliminar",
            deleteAria: "Eliminar elementos seleccionados",
        },
    },
}