import { 
    CssBaseline,
    Box,
    CircularProgress,
} from '@mui/material'

import {
    Root,
    Header,
    EdgeSidebar,
    SidebarContent,
    Content,
} from "@mui-treasury/layout"

import AppDrawer from 'layout/AppDrawer'
import AppHeader from 'layout/AppHeader'

const layout_config = {
    header: {
        config: {
            xs: {
                position: "sticky",
                height: 48,
            },
        },
    },
    leftEdgeSidebar: {
        config: {
            sm: {
                variant: "persistent",
                width: 256,
            },
        },
    },
  }

const AppLayout = (props) => {

    const initialState={
        leftEdgeSidebar: {
            open : props.drawer != false ? true : false,
        },
    }

    return (
        <div>
            <CssBaseline />
            <Root scheme={layout_config} initialState={initialState} >
                { props.header !== false ?
                    <Header>
                        <AppHeader title={props.title} drawer={props.drawer} />
                    </Header>
                : null}
                { props.drawer !== false ?
                    <EdgeSidebar anchor="left">
                        <SidebarContent>
                            <AppDrawer />
                        </SidebarContent>
                    </EdgeSidebar>
                : null}
                <Content>
                    <Box p={2}>
                        { props.loading ? <CircularProgress /> : props.children }
                    </Box>
                </Content>
            </Root>
        </div>
    )
}

export default AppLayout;