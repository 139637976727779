import{
    Grid,
    FormControlLabel,
    Switch,
} from '@mui/material'

const CustomTextField = (props) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
                control={<Switch color="primary" checked={props.value} onChange={props.onChange ? (event) => { props.onChange(event.target.checked) } : null} />}
                label={props.label}
                labelPlacement="start"
            />
        </Grid>
    )
}

export default CustomTextField