import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import{
    Toolbar,
    Box,
} from '@mui/material'

import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'
import BasicTable from 'components/BasicTable'
import Button from 'components/Button'

import { server_url } from 'config'

const title = 'Prizes Spin Wheel'

let columns = [
    { title: 'Name',    field: 'name' },
    { title: 'Description',    field: 'description'},
    { title: 'End Date',    field: 'end_date',   type : 'datetime' },
    { title: 'Total',  field: 'num_items' },
    { title: 'Status',  field: 'status', type : 'capitalize' }
]

const Prizesc = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const fetchData = () => {
        fetch(`${server_url}/prizesSpin/listbackend`)
        .then(response => {
            setLoading(false)

            return response.json()
        })
        .then(data => {
             console.log(data)
            setData(data)
        })
    }

    const editPrizesSpin = (row) => {
        console.log(row)
        navigate(`/prizesSpin/edit/${row._id}`)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                <Box sx={{ flexGrow : 1 }}/>
                <Button to='/prizesSpin/new' >
                    New Prize
                </Button>
            </Toolbar>
            { data.length ? <BasicTable columns={columns} data={data} onRowClick={editPrizesSpin} /> : <div>No Prizes to show</div>}
        </AppLayout>
    )
}

export default withSession(Prizesc)