import{
    Grid,
    TextField,
} from '@mui/material'

const CustomTextField = (props) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <TextField id="standard-basic" value={props.value} label={props.label} type={props.type} inputProps={{min : props.min, max: props.max}} variant="standard" fullWidth
            multiline={props.multiline ? props.multiline : null}
            onChange={props.onChange ? (event) => { props.onChange(event.target.value) } : null}
            InputProps={{
                readOnly: props.readonly,
              }}
            />
        </Grid>
    )
}

export default CustomTextField