import { Link } from "react-router-dom";

import{
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material'

import {
    Home as HomeIcon,
    List as ListIcon,
    Map as MapIcon,
    Gamepad as GamepadIcon,
} from "@mui/icons-material"

import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DescriptionIcon from '@mui/icons-material/Description';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const menu = [
    // {
    //     text: 'Configuration',
    //     sections: [
    //         // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
    //         // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
    //         { text : 'Regions', icon : <ListIcon />, url : '/regions' },
    //     ],
    // },
    {
        text: 'Tournaments',
        sections: [
            // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
            // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
            { text : 'List', icon : <ListIcon />, url : '/tournaments' },
        ],
    },
    {
        text: 'Shop',
        sections: [
            // { text : 'Gameplay Modes', icon : <GamepadIcon />, url : '/game_modes' },
            // { text : 'Maps', icon : <MapIcon />, url : '/maps' },
            { text : 'Bloomies', icon : <SwapHorizIcon />, url : '/bloomies' },
            { text : 'Categories', icon : <ViewModuleIcon />, url : '/categories' },
            { text : 'Products', icon : <DescriptionIcon />, url : '/products' },
        ],
    },
    {
        text: 'Prizes Chests',
        sections: [
            { text : 'List', icon : <ListIcon />, url : '/prizesc' },
        ],
    },
    {
        text: 'Prizes Spin Wheel',
        sections: [
            { text : 'List', icon : <ListIcon />, url : '/prizesSpin' },
        ],
    },
]

const AppDrawer = () => {
    return (
        <List>
            <ListItem button component={Link} to='/' >
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText>Home</ListItemText>
            </ListItem>
            {
            menu.map((category, i) => {
                return (
                    <div key={i}>
                        <ListItem>
                            <ListItemText>
                                {category.text}
                            </ListItemText>
                        </ListItem>
                        {
                        category.sections.map((item, i) => {
                            return (
                                <ListItem key={i} button component={Link} to={item.url} >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.text}</ListItemText>
                                </ListItem>
                            )

                        })
                        }
                    </div>
                )
            })
            }
        </List>

    )
}

export default AppDrawer;