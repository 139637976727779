import{
    Paper,
    Box,
    Grid,
    Typography,
} from '@mui/material'

const Form = (props) => {
    return (
        <Paper>
            <Box sx={{ flexGrow: 1, padding : 2, marginBottom : 2 }}>
                {props.title ? <Typography>{ props.title } </Typography> : null}
                <Grid container spacing={2} >
                    { props.children }
                </Grid>
            </Box>
        </Paper>
    )
}

export default Form