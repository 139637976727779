import { useState, useEffect } from "react"

import { server_url } from 'config'

import { getItem, setItem, delItem } from 'utils';

export function useSession() {
    const [user, setUser] = useState(null)
    const [needFetching, setNeedFetching] = useState(false)

    const getData = async() => {
        let token = getItem('token')

        const options = {
            crossDomain : true,
            method  : 'GET',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Token' : token,
            })
        }

        let response = await fetch(`${server_url}/users/data`, options)

        if(response && response.status == 200){
            setUser(await response.json())
        }else{
            delItem('token')
        }
    }

    useEffect(() => {
        if(!user){
            setNeedFetching(true)
        }
    }, [user])

    useEffect(() => {
        if (!needFetching) return;

        getData()
        
    }, [needFetching])

    const getToken = () => {
        return getItem('token')
    }

    const getUser = () => {
        return user
    }

    return {
        token : getToken(),
        user : getUser(),
    }
}