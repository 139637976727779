import {
    Box,
    IconButton,
} from '@mui/material'

import {
    useLayoutCtx,
} from "@mui-treasury/layout"

import {
    Menu,
    ChevronLeft
} from "@mui/icons-material"

const TriggerUsingHook = () => {
    const {
        state: { leftEdgeSidebar },
        toggleLeftSidebarOpen,
    } = useLayoutCtx()
    return (
        <IconButton onClick={toggleLeftSidebarOpen}>
            {leftEdgeSidebar?.open ? <ChevronLeft /> : <Menu />}
        </IconButton>
    )
}

const AppHeader = (props) => {
    return (
        <Box display="flex" alignItems="center" justify="center" gap={1} px={1}>
            { props.drawer !== false ?
                <TriggerUsingHook />
            : null}
            <div>
                {props.title}
            </div>
        </Box>
    )
}

export default AppHeader;