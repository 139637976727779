export function setItem(item, value){
    localStorage.setItem(item, value)
}

export function delItem(item){
    localStorage.removeItem(item)
}

export function getItem(item){
    let value = localStorage.getItem(item) || undefined

    return value
}

export async function getData(url, data, method){
    //console.log(data)

    if (!method) {
        method = 'GET'
    }

    let token = getItem('token') || undefined

    const options = {
        crossDomain : true,
        method  : method,
        headers : new Headers({
            'Content-Type' : 'application/json',
            // 'Access-Control-Allow-Origin' : '*',
            // 'Access-Control-Allow-Methods' : 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            'Token' : token,
        }),
        // mode    : 'cors',
        //cache   : 'default',
        body    : data ? JSON.stringify(data) : null,
    }

    let response

    try {
        // console.log(url, method, data, options)

        response = await fetch(url, options)

    } catch (err) {
        console.log(err)
    }

    console.log(response)

    return response
}

export function getName(data, _id, key){
    let name

    for (var i = 0; i < data.length; i++) {
        if(data[i]._id === _id){
            name = data[i][key]
        }
    }

    return name
}

export function currencyFormat(num) {
    if(!num){
        num = 0
    }

    num = parseFloat(num)

    return '$ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function titleCase(str) {
    if(!str){
        str = ''
    }
    
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
       // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    // Directly return the joined string
    return splitStr.join(' '); 
}

export function capitalize(str){
    if(!str){
        str = ''
    }
    
    return str.charAt(0).toUpperCase() + str.slice(1)
}
