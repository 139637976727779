import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs';

import{
    Grid,
} from '@mui/material'

const CustomDatePicker = (props) => {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <DateTimePicker
                label={props.label}
                slotProps={{ textField: { variant: 'standard', fullWidth: true } }}
                value={dayjs(props.value)}
                onChange={(newValue) => props.onChange(newValue)}
                //disableOpenPicker={props.readonly}
                readOnly={props.readonly}
            />
        </Grid>
    )
}

export default CustomDatePicker