import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router';

import{
    Grid,
    Toolbar,
    Box,
    Paper,
} from '@mui/material'

import { server_url } from 'config'
import { getItem, capitalize } from 'utils'

import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'
import BasicTable from 'components/BasicTable'
import TextField from 'components/TextField'
import Form from 'components/Form'
import DatePicker from 'components/DatePicker'
import Select from 'components/Select'
import Button from 'components/Button'
import { generatePath } from "../../../node_modules/react-router-dom/dist/index";

const title = 'New Prizes'

const item_columns = [
    { title: 'internalId', field: 'internalId' },
    { title: 'status',    field: 'status'    },
    { title: 'user Id',   field: 'username'   },
    { title: 'nickname',   field: 'nickname'   },
    { title: 'Spawn Date',    field: 'spawnDate' , type:'datetime'   },
    { title: 'claim Date',    field: 'claimDate',  type:'datetime' },
]

const claimed_columns = [
    { title: 'internalId', field: 'internalId' },
    { title: 'status', field: 'status'    },
    { title: 'username',   field: 'username'   },
]

const PrizeType = [
    {
        name : "SPL Token",
        id : "spltoken"
    },
    {
        name : "Simple Text with GUID",
        id : "text"
    },
    {
        name : "Physical Item",
        id : "physical"
    }
]

const item3d = [
    {
        name : "Bloomies",
        id : "BloomieFace"
    },
    {
        name : "Whitelist",
        id : "Coin_whitelist"
    },
    {
        name : "Glytch card",
        id : "Coin_Glytch"
    },
    {
        name : "$GECKO",
        id : "Coin_geckos"
    },
    {
        name : "$BONK",
        id : "Coin_bonk"
    },
    {
        name : "Helium Hotspot",
        id : "Coin_Helium"
    },
    {
        name : "$JUP",
        id : "Coin_jup"
    },
    {
        name : "$EVOL",
        id : "Coin_evol"
    },
    {
        name : "Eyekon",
        id : "Coin_eyekon"
    },
    {
        name : "HappyVibes",
        id : "Coin_happyVibes"
    }
]

const chestMat = [
    {
        name : "Common",
        id : "common"
    },
    {
        name : "Epic",
        id : "epic"
    },
    {
        name : "Legendary",
        id : "legendary"


    },
    {
        name : "Mythic",
        id : "mythic"
    }
]



const prize = {
    position : 1,
    prize : ''
}

const conditions_type = [
    {
        name : "Token",
        id : "token"
    },
    {
        name : "Level",
        id : "level"
    },
    {
        name : "Quota",
        id : "quota"
    },
]

const conditions_frequency = [
    {
        name : "Daily",
        id : "daily"
    },
    {
        name : "Monthly",
        id : "monthly"
    },
    {
        name : "Total",
        id : "total"
    },
]

const NewPrizesSpin = () => {
    const navigate = useNavigate()
    const { id } = useParams()

    const [readonly, setLReadOnly] = useState(false)

    const [loading, setLoading] = useState(true)
    const [regions, setRegions] = useState([])


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [start_date, setStartDate] = useState('')
    const [end_date, setEndDate] = useState('')
    const [num_items, setNumItems] = useState(0)
    const [items,setItems]  = useState([]);
        const [item_type, setItemType] = useState("text");
        const [item_material, setItemMat] = useState("common");
        const [item_3d, setItem3D] = useState("bloomies");
        const [item_spawnProbabilities, setSpawnProbabilities] = useState(100);
        const [item_maxActive, setMaxActive] = useState(10);
        const [item_message, setItemMessage] = useState("Claimed!");
        const [item_sku, setItemSKU] = useState("");
    const [conditions, setConditions] = useState([]);
    const [status, setStatus] = useState('new')
    const [region, setRegion] = useState('us')

    const round = {
     
    }
    const [rounds, setRounds] = useState([round])

    const fetchRegions = () => {
        fetch(`${server_url}/regions/list`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setRegions(data)
        })
    }

   /* const fetchPlayers = (id) => {
        fetch(`${server_url}/prizesc/${id}/players`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            setPlayers(data)
        })
    }*/

    const fetchPrizes = (id) => {
        fetch(`${server_url}/prizesSpin/${id}`)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            if(data.status != 'created' || data.status != 'new' ){
                //fetchPlayers(id)
            }

            if(data.status == 'created' || data.status == 'new'){
                setLReadOnly(false)
            }

            setName(data.name)
            setDescription(data.description)
            setStartDate(data.start_date)
            setEndDate(data.end_date)
            setNumItems(data.num_items)
            setItems(data.items)
            setItemType(data.item_type);
            setItemMat(data.item_material);
            setItem3D(data.item_3d)
            setSpawnProbabilities(data.item_spawnProbabilities)
           // setMaxPeruser(data.item_maxPeruser)
            setMaxActive(data.item_maxActive)
            setItemMessage(data.item_message)
            setItemSKU(data.item_sku)
            setConditions(data.conditions)
            setStatus(data.status)
            setRegion(data.region)
            setLoading(false)

        })
    }

    const savePrizesc = () => {
      
        let data = {
            status: status,
            name : name,
            description : description,
            start_date : start_date,
            end_date : end_date,
            item_type : item_type,
            item_material : item_material,
            item_3d : item_3d,
            num_items : num_items,
            item_maxActive : item_maxActive,
            item_spawnProbabilities : item_spawnProbabilities,
            item_message : item_message,
            item_sku : item_sku,
            region : region,
            items : items,
            conditions: conditions,
        }

        console.log(data,"DAta");

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        let url = `${server_url}/prizesSpin/save`
        if(id){
            url = `${server_url}/prizesSpin/${id}/save`
        }
        
        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            navigate(`/prizesSpin/edit/${data._id}`)
            navigate(0)
        })
    }

    const generateItems = () => {
        let data = {
            status : 'active'
        }

        let options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Token' : getItem('token')
            },
            body: JSON.stringify(data),
        }

        let url = `${server_url}/prizesSpin/${id}/generate`
        
        fetch(url, options)
        .then(response => {
            return response.json()
        })
        .then(data => {
            console.log(data)

            navigate(`/prizesSpin/edit/${data._id}`)
            navigate(0)
        })
    }

    const addCondition = () => {
        let c_cond = conditions.slice()
        
        c_cond.push({
            condition : undefined,
            value : undefined,
        })

        setConditions(c_cond)
    }

    
    const setItemTypeF = (event) => {
        console.log(event);
        setItemType("event")
    }

    const setConditionData = (i, key, value) => {
        console.log(i, key, value)

        let c_cond = conditions.slice()

        c_cond[i][key] = value

        setConditions(c_cond)
    }

    

    useEffect(() => {
        // console.log(id)
        if(id){
            fetchPrizes  (id)
        }else{
            setLoading(false)
        }

        fetchRegions()
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                Status: { capitalize(status) }
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'created' ? <Button onClick={generateItems}>Activate</Button> : null}
                <Box sx={{ width : 10 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={savePrizesc}>Save</Button> : null}
            </Toolbar>

            <Form>
                <TextField label="Name" value={name} onChange={setName} readonly={readonly} />
                <TextField label="Description" value={description} onChange={setDescription} readonly={readonly} />
                <DatePicker label="Start date" value={start_date} onChange={setStartDate} readonly={readonly} />
                <DatePicker label="End date"  value={end_date} onChange={setEndDate} readonly={readonly} /> 
                <Select label="Region" data={regions} field="code" value={region} onChange={setRegion} readonly={readonly} />
                <TextField label="Number of Items" type="number" value={num_items} onChange={setNumItems} readonly={readonly} />

                <Select label="Item Type" data={PrizeType} field="id" value={item_type}  onChange={(value) => setItemType(value)} readonly={readonly} />
                <Select label="Chest Mat" data={chestMat} field="id" value={item_material}  onChange={(value) => setItemMat(value)} readonly={readonly} />
                <Select label="Item 3D" data={item3d} field="id" value={item_3d}  onChange={(value) => setItem3D(value)} readonly={readonly} />
                <TextField label="Spawn Probability" type="number" value={item_spawnProbabilities} onChange={setSpawnProbabilities} readonly={readonly} />
                <TextField label="Max Active" type="number" value={item_maxActive} onChange={setMaxActive} readonly={readonly} />
                <TextField label="Claim Message"  value={item_message} onChange={setItemMessage} readonly={readonly} />
                <TextField label="Item SKU" value={item_sku} onChange={setItemSKU} readonly={readonly} />
                
            </Form>

            <Toolbar>
                Conditions
                <Box sx={{ flexGrow : 1 }}/>
                { status == 'new' || status == 'created' ? <Button onClick={addCondition} >Add condition</Button> : null}
            </Toolbar>

            {
                conditions.map((condition, i) => {
                    return (
                        <Form>
                            <Select label="Type" data={conditions_type} field="id" value={condition.condition} onChange={(value) => setConditionData(i, 'condition', value)} readonly={readonly} />
                            <TextField label="Description" value={condition.description} onChange={(value) => setConditionData(i, 'description', value)} readonly={readonly} />
                         {/*    {condition.condition=="quota" ? (
                               <Select label="Type" data={conditions_frequency} field="id" value={condition.value} onChange={(value) => setConditionData(i, 'condition', value)} readonly={readonly} />
                            ) : ( 
                            */}
                                <TextField label="Value" value={condition.value} onChange={(value) => setConditionData(i, 'value', value)} readonly={readonly} />
                            
                            
                            <TextField label="Amount" value={condition.amount} onChange={(value) => setConditionData(i, 'amount', value)} readonly={readonly} />
                        </Form>
                    )
                })
            }

        

         

            { status != 'new' && status != 'created' ? <div>
                <Toolbar>
                     Items : { num_items }
                    <Box sx={{ flexGrow : 1 }}/>
                </Toolbar>
                { items.length ? <BasicTable columns={item_columns} data={items} /> : <div>No Items generated!</div>}

            </div> : null}
        </AppLayout>
    )
}

export default withSession(NewPrizesSpin)