import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import{
    Toolbar,
    Box,
} from '@mui/material'

import withSession from "hocs/withSession"

import AppLayout from 'layout/AppLayout'
import BasicTable from 'components/BasicTable'
import Button from 'components/Button'

import { server_url } from 'config'

const title = 'Tournaments'

let columns = [
    { title: 'Name',    field: 'name' },
    { title: 'Round Dates',    field: 'rounds',   type : 'datetimearray' },
    { title: 'Rounds',  field: 'num_rounds' },
    { title: 'Min players', field: 'min_players' },
    { title: 'Max players', field: 'max_players' },
    { title: 'Subscribed players', field: 'num_players' },
    { title: 'Status',  field: 'status', type : 'capitalize' },
]

const Tournaments = () => {
    const navigate = useNavigate()
    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const fetchData = () => {
        fetch(`${server_url}/tournaments/listbackend`)
        .then(response => {
            setLoading(false)

            return response.json()
        })
        .then(data => {
            // console.log(data)
            
            setData(data)
        })
    }

    const editTournament = (row) => {
        console.log(row)
        navigate(`/tournaments/edit/${row._id}`)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <AppLayout title={title} loading={loading} >
            <Toolbar>
                <Box sx={{ flexGrow : 1 }}/>
                <Button to='/tournaments/new' >
                    New tournament
                </Button>
            </Toolbar>
            { data.length ? <BasicTable columns={columns} data={data} onRowClick={editTournament} /> : <div>No tournaments to show</div>}
        </AppLayout>
    )
}

export default withSession(Tournaments)